import React, { PropsWithChildren, ReactNode } from 'react';
import { Theme } from '@material-ui/core';

import al from '../media/team/al.jpg';
import alVid from '../media/team/alVid.mp4';
import alex from '../media/team/alex.jpg';
import alexVid from '../media/team/alexVid.mp4';
import alice from '../media/team/alice.jpg';
import aliceVid from '../media/team/aliceVid.mp4';
import allan from '../media/team/allan.jpg';
import allanVid from '../media/team/allanVid.mp4';
import ally from '../media/team/ally.jpg';
import allyVid from '../media/team/allyVid.mp4';
import amber from '../media/team/amber.jpg';
import amberVid from '../media/team/amberVid.mp4';
import andrew from '../media/team/andrew.jpg';
import andrewVid from '../media/team/andrewVid.mp4';
import attila from '../media/team/attila.jpg';
import attilaVid from '../media/team/attilaVid.mp4';
import craig from '../media/team/craig.jpg';
import craigVid from '../media/team/craigVid.mp4';
import elisa from '../media/team/elisa.jpg';
import elisaVid from '../media/team/elisaVid.mp4';
import eric from '../media/team/eric.jpg';
import ericVid from '../media/team/ericVid.mp4';
import erin from '../media/team/erin.jpg';
import erinVid from '../media/team/erinVid.mp4';
import foster from '../media/team/foster.png';
import hannah from '../media/team/hannah.jpg';
import hannahVid from '../media/team/hannahVid.mp4';
import harmony from '../media/team/harmony.jpg';
import harmonyVid from '../media/team/harmonyVid.mp4';
import jamie from '../media/team/jamie.jpg';
import jamieVid from '../media/team/jamieVid.mp4';
import jesse from '../media/team/jesse.jpg';
import jesseVid from '../media/team/jesseVid.mp4';
import joe from '../media/team/joe.jpg';
import joeVid from '../media/team/joeVid.mp4';
import jonathan from '../media/team/jonathan.jpg';
import jonathanVid from '../media/team/jonathanVid.mp4';
import katie from '../media/team/katie.jpg';
import katieVid from '../media/team/katieVid.mp4';
import mia from '../media/team/mia.jpg';
import miaVid from '../media/team/miaVid.mp4';
import natasha from '../media/team/natasha.jpg';
import natashaVid from '../media/team/natashaVid.mp4';
import radu from '../media/team/radu.jpg';
import raduVid from '../media/team/raduVid.mp4';
import rafael from '../media/team/rafael.jpg';
import rafaelVid from '../media/team/rafaelVid.mp4';
import rebeccaE from '../media/team/rebeccaE.jpg';
import rebeccaEVid from '../media/team/rebeccaEVid.mp4';
import shalana from '../media/team/shalana.jpg';
import shalanaVid from '../media/team/shalanaVid.mp4';
import theresa from '../media/team/theresa.jpg';
import theresaVid from '../media/team/theresaVid.mp4';
import vas from '../media/team/vas.jpg';
import vasVid from '../media/team/vasVid.mp4';

export type Team = 'product-development' | 'revenue' | 'operations' | 'pets';
export interface TeamMember {
  name: string;
  emoji: string;
  title: string;
  team?: Team;
  photo: string;
  mp4Video?: string; // from gif. i.e. go to giphy page with gif -> click Share -> select mp4 link and copy -> go to link -> save video as... -> compress if needed (aim for under 1MB)
  bio: ReactNode;
}

export const BioText = (props: PropsWithChildren<{ className?: string }>) => (
  <p
    className={props.className}
    css={(theme: Theme) => ({
      fontSize: '1.8rem',
      lineHeight: 1.4,
      fontWeight: 400,
      margin: 0,
      ['@media (min-width: 350px)']: {
        fontSize: '2rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.8rem',
      },
    })}
  >
    {props.children}
  </p>
);

export function getNumMembers(team: Team) {
  return teamMembers.filter((mem) => mem.team === team).length;
}

export const teamMembers: TeamMember[] = [
  {
    name: 'Alex Stylianoudis',
    emoji: '🏂',
    title: 'Head of Legal, Finance & Operations',
    team: 'operations',
    photo: alex,
    mp4Video: alexVid,
    bio: (
      <BioText>
        A lover of all things Christmas and a specialist in financial and legal... stuff. Knows the entire script from
        The Office and most likely to be on his way to the airport. Passionate about streamlining and automating legal
        and financial processes.
      </BioText>
    ),
  },
  {
    name: 'Alice Ralph',
    emoji: '🐇',
    title: 'Lead Product Designer',
    team: 'product-development',
    photo: alice,
    mp4Video: aliceVid,
    bio: (
      <BioText>
        When she's not jamming in Figma, you will find her watching live music, reading, or attempting to master a new
        skill. Alice loves building things, making art, and understanding how things work - and design combines all of
        those!
      </BioText>
    ),
  },
  {
    name: 'Allan Godding',
    emoji: '🌴',
    title: 'Lead Android Engineer',
    team: 'product-development',
    photo: allan,
    mp4Video: allanVid,
    bio: (
      <BioText>
        Allan's been coding little green robots since Android 1.0. He can most often be found playing ultimate, golfing,
        surfing, or travelling to sunny destinations (to do more surfing).
      </BioText>
    ),
  },
  {
    name: 'Ally McDermott',
    emoji: '🧜‍♀️',
    title: 'Customer Operations Specialist',
    team: 'revenue',
    photo: ally,
    mp4Video: allyVid,
    bio: (
      <BioText>
        Spends her days embroidering, watching TV (there’s likely nothing she hasn’t seen), playing games and talking
        about hairless cats! Ally's passionate about helping others and finding solutions, believing anyone can succeed
        when given the right tools.
      </BioText>
    ),
  },
  {
    name: 'Alyshahn Kara',
    emoji: '🧸',
    title: 'Chief Revenue Officer',
    team: 'revenue',
    photo: al,
    mp4Video: alVid,
    bio: (
      <BioText>
        Obsessed with making sales more human and something that customers look forward to and enjoy! Alyshahn believes
        a happy team = happy customers. He's always looking for ways to spend time with his husky Zoey or to tee it up
        at golf courses around the world.
      </BioText>
    ),
  },
  {
    name: 'Amber Charron',
    emoji: '🛁',
    title: 'Customer Success Lead',
    team: 'revenue',
    photo: amber,
    mp4Video: amberVid,
    bio: (
      <BioText>
        Keen to the title ‘Scavenger Hunt Expert.’ She believes in a human approach to the customer experience and is
        continually looking to incorporate goose puns into any conversation. Her goal is to try every hobby under the
        sun but commit to none of them.
      </BioText>
    ),
  },
  {
    name: 'Andrew Cross',
    emoji: '🌯',
    title: 'Founder & CEO',
    team: 'operations',
    photo: andrew,
    mp4Video: andrewVid,
    bio: (
      <BioText>
        Proud inventor of the perfect breakfast bagel. When travelling, he spends days searching for all the tastiest
        food &amp; coffee spots, making a list of deliciousness for each destination. A huge advocate of remote work
        &amp; obsessed with creating the perfect distributed team culture!
      </BioText>
    ),
  },
  {
    name: 'Attila Hegedüs',
    emoji: '⭐',
    title: 'Lead iOS Engineer',
    team: 'product-development',
    photo: attila,
    mp4Video: attilaVid,
    bio: (
      <BioText>
        A true Apple fanboy and ex-pro Gomoku player. Attila is very enthusiastic about iOS development - always
        striving to deliver honktacular releases while keeping up with the latest and greatest. When not writing 1s and
        0s, he loves to explore the world as a plant-based cyclist.
      </BioText>
    ),
  },
  {
    name: 'Craig Cordell',
    emoji: '⚡️',
    title: 'Community Manager',
    team: 'revenue',
    photo: craig,
    mp4Video: craigVid,
    bio: (
      <BioText>
        Currently walking his dog Foster and listening to Springsteen, Craig is a proud member of the flock. Curious,
        kind, and creative, he is a passionate champion for the power of community, ready to make connections and build
        remarkable experiences for Goosechase creators everywhere.
      </BioText>
    ),
  },
  {
    name: 'Elisa Pallmer',
    emoji: '✨',
    title: 'Senior Graphic Designer',
    team: 'product-development',
    photo: elisa,
    mp4Video: elisaVid,
    bio: (
      <BioText>
        You can usually find her drawing quirky characters while listening to the most chilling horror podcast. She is
        always ready to bring your ideas to life on paper, screen and even fabric... Currently trying her best to
        moderate her consumption of chili powder and K-pop videos.
      </BioText>
    ),
  },
  {
    name: 'Eric Chiang',
    emoji: '🤘',
    title: 'Product Manager',
    team: 'product-development',
    photo: eric,
    mp4Video: ericVid,
    bio: (
      <BioText>
        Avid Hans Zimmer enthusiast who spends more time cycling than walking, Eric brings his background from the
        experience-creation industry to Goosechase as the expert in content creation and funnel building. He just got a
        new slow cooker and is always up for swapping recipes.
      </BioText>
    ),
  },
  {
    name: 'Erin Labrie',
    emoji: '🌷',
    title: 'Senior Account Manager',
    team: 'revenue',
    photo: erin,
    mp4Video: erinVid,
    bio: (
      <BioText>
        Obsessed with F1 and is convinced she was a race car driver in a past life. A spirited adventurer, in the summer
        she rides on the back of a Harley, exploring Canada and seeking the next best destination. Passionate when it
        comes to supporting others towards achieving growth and their goals!
      </BioText>
    ),
  },
  {
    name: 'Foster',
    emoji: '🎾',
    title: 'VP of Fetch',
    team: 'pets',
    photo: foster,
    bio: (
      <BioText>
        Foster Joseph Clyde Cordell never met a tennis ball he didn't love. This good boyyee keeps the neighbourhood
        squirrel free, cuddles closhe, and can clear a room with a single toot!
      </BioText>
    ),
  },
  {
    name: 'Hannah Catmur',
    emoji: '🦦',
    title: 'Head of Design',
    team: 'product-development',
    photo: hannah,
    mp4Video: hannahVid,
    bio: (
      <BioText>
        Designer by day, birdwatcher and nature nerd… also by day. She worked with Apple to design one of the first
        hiking apps for Apple Watch (and presented at the Keynote!). Has a deep love for adventures, crosswords, and
        Katsu Curry. Plans to one day own a miniature dachshund to name Tiffin.
      </BioText>
    ),
  },
  {
    name: 'Harmony Mahaffy',
    emoji: '🎮',
    title: 'Senior Mobile Engineer',
    team: 'product-development',
    photo: harmony,
    mp4Video: harmonyVid,
    bio: (
      <BioText>
        An avid adventurer both virtual &amp; IRL, Harmony loves gaming &amp; traveling with her family. She has
        background in Android &amp; iOS encompassing FinTech, eCommerce, Wine, and Gaming. Harmony has a passion for
        making tech accessible to everyone. Ask her about her patent!
      </BioText>
    ),
  },
  {
    name: 'Jamie Hale',
    emoji: '🎸',
    title: 'Principal Infrastructure & Security Engineer',
    team: 'product-development',
    photo: jamie,
    mp4Video: jamieVid,
    bio: (
      <BioText>
        Jamie likes to program things. As a veteran software engineer, he brings with him a breadth of experience and a
        life-long passion for the craft. He skis. He takes things apart and often puts them back together again. He
        plays guitar and drums and Dungeons &amp; Dragons.
      </BioText>
    ),
  },
  {
    name: 'Jesse Kaunisviita',
    emoji: '🥞',
    title: 'Senior Software Engineer',
    team: 'product-development',
    photo: jesse,
    mp4Video: jesseVid,
    bio: (
      <BioText>
        Whether it's ant keeping, banjo playing, cooking, drawing, echolocating or flipping fat stacks of pancakes,
        Jesse loves learning and honing new skills. They are passionate about all things DIY and writing code that makes
        a physical impact on the world.
      </BioText>
    ),
  },
  {
    name: 'Joe Denomme',
    emoji: '🍿',
    title: 'Account Executive',
    team: 'revenue',
    photo: joe,
    mp4Video: joeVid,
    bio: (
      <BioText>
        Always outdoors unless there's something good on TV, Joe is here to give out great ideas for events, how to
        build a fun team culture, or what podcast to listen to next. He's passionate about helping people find their
        next great adventure, whether it's within a Goosechase game or your weekend plans.
      </BioText>
    ),
  },
  {
    name: 'Jonathan Solway',
    emoji: '😎',
    title: 'Customer Success Specialist',
    team: 'product-development',
    photo: jonathan,
    mp4Video: jonathanVid,
    bio: (
      <BioText>
        Always the first person to take control of the aux or talk your ear off about a new movie, Jonathan is a true
        lover of the arts. Due to the stress of being an NFL fan, he gets his well earned R&R through travel. His life
        mission is to make sure everyone is having a good time.
      </BioText>
    ),
  },
  {
    name: 'Katie Canton',
    emoji: '🙌',
    title: 'Head of Brand Experience',
    team: 'revenue',
    photo: katie,
    mp4Video: katieVid,
    bio: (
      <BioText>
        Creative storyteller who loves building experiences that educate, engage and make people smile! Truly believe a
        marketing team's success hinges on its ability to add value at every single touchpoint. An avid traveller,
        obsessive puzzler, and novice yet enthusiastic saxophonist.
      </BioText>
    ),
  },
  {
    name: 'Mia David',
    emoji: '🥟',
    title: 'Integrated Marketing Manager',
    team: 'revenue',
    photo: mia,
    mp4Video: miaVid,
    bio: (
      <BioText>
        Before joining the flock, Mia spent years in Public Relations for some of your favourite beauty and fashion
        brands. A dreamer, she is fascinated by the power of thoughtful art, copy, and design. A doer, she champions
        storytelling that is equal parts strategic and sensitive with a wink of good humour.
      </BioText>
    ),
  },
  {
    name: 'Natasha DB',
    emoji: '🧘🏿',
    title: 'Head of People',
    team: 'operations',
    photo: natasha,
    mp4Video: natashaVid,
    bio: (
      <BioText>
        Has unrealistic dreams of becoming a Yogini and is just as focused on the wellness and happiness of the flock at
        Goosechase. As an unrealised Cabaret Queen, Natasha manages shows around London and often "performs" karaoke for
        her family and little dog, Raffi.
      </BioText>
    ),
  },
  {
    name: 'Radu Sepetan',
    emoji: '🤷‍♂️',
    title: 'Lead Software Engineer',
    team: 'product-development',
    photo: radu,
    mp4Video: raduVid,
    bio: (
      <BioText>
        Radu enjoys rising through the ranks in his favourite video games. When he's not climbing virtually, you can
        find him doing it physically at the bouldering gym. To give his mind and body a rest he enjoys watching movies,
        TV shows, and Formula 1!
      </BioText>
    ),
  },
  {
    name: 'Rafael Franco',
    emoji: '⚽',
    title: 'Senior Software Engineer',
    team: 'product-development',
    photo: rafael,
    mp4Video: rafaelVid,
    bio: (
      <BioText>
        Rafael is passionate about football (not the American version) and his dogs. Between cheering for his team, he's
        a culinary maestro and a Pitaya plant commander. He also enjoys karaoke at home with his girlfriend and
        playfully wrestles over who's slacking off in Overcooked!
      </BioText>
    ),
  },
  {
    name: 'Rebecca Everson',
    emoji: '🧩',
    title: 'Community Coordinator',
    team: 'revenue',
    photo: rebeccaE,
    mp4Video: rebeccaEVid,
    bio: (
      <BioText>
        A dedicated teacher and proud Croc wearer, Rebecca is always willing to lend a hand and find the positive in the
        chaos of teaching. When she isn’t teaching or updating the Game Library, she is huddled under a blanket,
        drinking tea, and watching Gilmore Girls or the latest in The Bachelor!
      </BioText>
    ),
  },
  {
    name: 'Shalana Hunt',
    emoji: '🔮',
    title: 'Finance & Legal Specialist',
    team: 'operations',
    photo: shalana,
    mp4Video: shalanaVid,
    bio: (
      <BioText>
        As a lover of Harry Potter and The Office, she can be found curled up on the couch with her dog Sophie ready to
        press "Next Episode". When she isn't binging her favourite movies or TV shows, you can find Shalana outside
        getting a breath of fresh air with her furry friend!
      </BioText>
    ),
  },
  {
    name: "Theresa O'Brien",
    emoji: '🧘',
    title: 'Account Manager',
    team: 'revenue',
    photo: theresa,
    mp4Video: theresaVid,
    bio: (
      <BioText>
        When she's not connecting with clients, you can find her traveling, learning a new hobby, practicing yoga, and
        hanging out with her fluffy cat Mitzi (who often tries to join her client zoom calls). She is passionate about
        ensuring her customers have the best possible Goosechase experience!
      </BioText>
    ),
  },
  {
    name: 'Vasanth Nagasivam',
    emoji: '👾',
    title: 'VP of Product Development',
    team: 'product-development',
    photo: vas,
    mp4Video: vasVid,
    bio: (
      <BioText>
        With his love of all things related to technology, gaming and startup culture, Vasanth is dedicated to improving
        Goosechase for all our customers. Away from his screens, you can find him photographing the world or hunting
        down the best plant-based junk food across London.
      </BioText>
    ),
  },
];
